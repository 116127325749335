



































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AccelChartS from "../../charts/AccelChartS.vue";
import AccelChartApx from "../../charts/AccelChartApx.vue";
import GyroChartS from "../../charts/GyroChartS.vue";
import GyroChartApx from "../../charts/GyroChartApx.vue";
import TemparatureChartS from "../../charts/TemparatureChartS.vue";
import TemparatureChartApx from "../../charts/TemparatureChartApx.vue";

import DaterangePicker from "../../ui/Daterange-Picker.vue";

import "leaflet/dist/leaflet.css";
import L from "leaflet";
import moment from "moment";

@Component({
  components: {
    AccelChartS,
    GyroChartS,
    TemparatureChartS,
    DaterangePicker,
    AccelChartApx,
    GyroChartApx,
    TemparatureChartApx,
  },
})
export default class AssetContent extends Vue {
  @Prop() basicAccelGyroData!: any;
  @Prop() basicDetailData!: any;

  // myInterval: any = setInterval(this.fetchEventDetailEveryFiveSec, 5000);

  accelChartData: any = [...this.basicAccelGyroData];
  gyroChartData: any = [...this.basicAccelGyroData];
  tempChartData: any = [...this.basicAccelGyroData];

  accelThemeChanged = false;
  gyroThemeChanged = false;
  tempThemeChanged = false;

  toggleAccelTheme() {
    this.accelChartData = [];
    this.accelThemeChanged = !this.accelThemeChanged;
    this.fetchEventDetail("accel", "", "", this.accelChartPicker);
  }
  toggleGyroTheme() {
    this.gyroChartData = [];
    this.gyroThemeChanged = !this.gyroThemeChanged;
    this.fetchEventDetail("gyro", "", "", this.gyroChartPicker);
  }
  toggleTempTheme() {
    this.tempChartData = [];
    this.tempThemeChanged = !this.tempThemeChanged;
    this.fetchEventDetail("temp", "", "", this.tempChartPicker);
  }

  accelChartPicker = "daily";
  gyroChartPicker = "daily";
  tempChartPicker = "daily";

  @Watch("accelChartPicker")
  watchaccelChartPicker() {
    this.fetchEventDetail("accel", "", "", this.accelChartPicker);
  }

  @Watch("gyroChartPicker")
  watchgyroChartPicker() {
    this.fetchEventDetail("gyro", "", "", this.gyroChartPicker);
  }

  @Watch("tempChartPicker")
  watchtempChartPicker() {
    this.fetchEventDetail("temp", "", "", this.tempChartPicker);
  }

  mounted() {
    this.initLeafletMap();
  }

  access_token =
    "pk.eyJ1Ijoia3VzaGFua3VyeGQiLCJhIjoiY2s1MXZvbHAxMGZoaDNmcGwzdXo4ZHgwNSJ9.kqBU5Wrn2yMM1RXbK3T9Gg";
  center: any = [37.7749, -122.4194];

  mapDiv: any = null;

  initLeafletMap() {
    this.mapDiv = L.map("mapContainer").setView(this.center, 13);

    this.watchThemeChange();

    this.addMyMarkerToMap(this.basicDetailData);
  }

  @Watch("$store.state.themeAT")
  watchThemeChange() {
    L.tileLayer(
      `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${this.access_token}`,
      {
        attribution: "Durbin",
        maxZoom: 18,
        id:
          this.$store.state.themeAT === "dark"
            ? "mapbox/dark-v10"
            : "mapbox/light-v10",
        accessToken: this.access_token,
      }
    ).addTo(this.mapDiv);
  }

  selectedMarker: any = null;
  popup: any = null;
  addMyMarkerToMap(asset: any) {
    if (asset.site.latitude === null || asset.site.longitude === null) {
      return;
    }

    if (this.mapDiv === null) {
      this.initLeafletMap();
    }

    const myIcon = L.icon({
      iconUrl: require("../../../assets/map-pin.svg"),
      iconSize: [38, 95],
      iconAnchor: [22, 66],
      popupAnchor: [-10, -30],
    });
    if (this.selectedMarker !== null) {
      this.mapDiv.removeLayer(this.selectedMarker);
    }
    this.selectedMarker = L.marker(
      [asset.site.latitude, asset.site.longitude],
      {
        icon: myIcon,
      }
    );

    this.popup = L.popup({ offset: [0, -30] })
      .setLatLng([asset.site.latitude, asset.site.longitude])
      .setContent(
        `<p class='font-bold'>${asset.site.address || "-"}<br/>${
          asset.site.city || "-"
        }, ${asset.site.state || "-"} , ${asset.site.country || "-"}</p>`
      )
      .openOn(this.mapDiv);

    this.mapDiv.addLayer(this.selectedMarker);
    this.mapDiv.flyTo([asset.site.latitude, asset.site.longitude], 18.0);
  }

  onAccelDateChange(dateRange) {
    this.fetchEventDetail(
      "accel",
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      ""
    );
  }
  onGyroDateChange(dateRange) {
    this.fetchEventDetail(
      "gyro",
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      ""
    );
  }
  onTempDateChange(dateRange) {
    this.fetchEventDetail(
      "temp",
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      ""
    );
  }

  async fetchEventDetail(
    activeChart: string,
    startDate: string,
    endDate: string,
    picker: string
  ) {
    try {
      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-accel-gyro/${this.basicDetailData.id}`;

      switch (activeChart) {
        case "accel":
          if (this.accelThemeChanged) {
            url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-accel-gyro-2/${this.basicDetailData.id}`;
          }
          break;
        case "gyro":
          if (this.gyroThemeChanged) {
            url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-accel-gyro-2/${this.basicDetailData.id}`;
          }
          break;
        case "temp":
          if (this.tempThemeChanged) {
            url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-accel-gyro-2/${this.basicDetailData.id}`;
          }
          break;
        default:
          break;
      }

      if (startDate && endDate) {
        if (startDate === endDate) {
          url += `?fixedDate=${startDate}`;
        } else {
          url += `?startDate=${startDate}&endDate=${endDate}`;
        }

        // if (this.myInterval != null) {
        //   clearInterval(this.myInterval);
        //   this.myInterval = null;
        // }
      } else if (picker) {
        url += `?${picker}=true`;
        // if (this.myInterval == null) {
        //   this.myInterval = setInterval(
        //     this.fetchEventDetailEveryFiveSec,
        //     5000
        //   );
        // }
      }

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        const data = await response.json();

        switch (activeChart) {
          case "accel":
            this.accelChartData = [...data];
            break;
          case "gyro":
            this.gyroChartData = [...data];
            break;
          case "temp":
            this.tempChartData = [...data];
            break;
          default:
            break;
        }

        if (data.length > 0) {
          this.lastTime = data[data.length - 1].time;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  lastTime = null;
  async fetchEventDetailEveryFiveSec() {
    try {
      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-accel-gyro/${this.basicDetailData.id}`;

      if (this.lastTime == null) {
        if (this.basicAccelGyroData.length > 0) {
          this.lastTime = this.basicAccelGyroData[
            this.basicAccelGyroData.length - 1
          ].time;
          url += `?lastTime=${this.lastTime}`;
        } else {
          return;
        }
      } else {
        url += `?lastTime=${this.lastTime}`;
      }

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        const data = await response.json();

        this.accelChartData = [...this.accelChartData, ...data];
        this.gyroChartData = [...this.gyroChartData, ...data];
        this.tempChartData = [...this.tempChartData, ...data];

        if (data.length > 0) {
          this.lastTime = data[data.length - 1].time;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  beforeDestroy() {
    // if (this.myInterval != null) {
    //   clearInterval(this.myInterval);
    //   this.myInterval = null;
    //   console.log("Interval Destroyed");
    // }
  }
}
