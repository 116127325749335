var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6"},[_c('div',{staticClass:"col-span-12 mt-8"},[_c('div',{staticClass:"intro-y flex items-center h-10"},[_c('ArrowLeftIcon',{staticClass:"sm:w-6 sm:h-6 sm:mr-6 w-5 h-5 mr-4 cursor-pointer",on:{"click":function($event){return _vm.$router.back()}}}),_c('h2',{staticClass:"sm:text-2xl text-xl font-medium truncate mr-5"},[_vm._v(" Asset Telematics ")]),_c('span',{staticClass:"ml-auto flex text-theme-1 dark:text-theme-10 cursor-pointer",on:{"click":_vm.reloadData}},[_c('RefreshCcwIcon',{staticClass:"w-4 h-4 mr-3"}),_vm._v(" Reload Data ")],1)],1),(_vm.isLoading)?_c('Loader'):_vm._e(),(!_vm.isLoading && _vm.showNoData)?_c('NoData',{attrs:{"onReloadData":_vm.reloadData,"type":_vm.noDataType}}):_vm._e(),(!_vm.isLoading && _vm.basicDetailData)?_c('AssetPreview',{attrs:{"basicDetailData":_vm.basicDetailData,"onChangeTab":_vm.onChangeTab,"activeTab":_vm.activeEventDetailTab}}):_vm._e(),(
        !_vm.isLoading &&
        _vm.basicAccelGyroData &&
        _vm.basicDetailData &&
        _vm.selectedTab === 0
      )?_c('AssetContent',{attrs:{"basicAccelGyroData":_vm.basicAccelGyroData,"basicDetailData":_vm.basicDetailData}}):_vm._e(),(
        !_vm.isLoading &&
        _vm.basicAccelGyroData &&
        _vm.basicDetailData &&
        _vm.selectedTab === 1
      )?_c('AssetUsageActualHours',{attrs:{"basicDetailData":_vm.basicDetailData,"basicAccelGyroData":_vm.usageActualHoursData}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }