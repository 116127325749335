























































import { Component, Vue } from "vue-property-decorator";
import { ArrowLeftIcon, RefreshCcwIcon } from "vue-feather-icons";
import AssetPreview from "../components/telematics/device-events/Asset-Preview.vue";
import AssetContent from "../components/telematics/device-events/Asset-Content.vue";
import AssetUsageActualHours from "../components/telematics/device-events/Asset-UsageActualHours.vue";

import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";

@Component({
  components: {
    ArrowLeftIcon,
    RefreshCcwIcon,
    AssetPreview,
    Loader,
    NoData,
    AssetContent,
    AssetUsageActualHours,
  },
})
export default class TelematicsEventDetail extends Vue {
  isLoading = false;
  showNoData = false;
  noDataType = "Error";
  basicDetailData = "";
  basicAccelGyroData = "";
  usageActualHoursData = "";
  selectedTab = 0;
  activeEventDetailTab = 0;

  mounted() {
    const id = this.$router.currentRoute.params.id || "";
    if (id !== "") {
      this.fetchEventDetail(id);
      this.fetchAssetWithId(id);
      this.fetchUsageActualHours(id);
    }

    const usageActual: any =
      this.$router.currentRoute.query.usageActual || null;
    if (usageActual) {
      this.onChangeTab(1);
    }
  }

  reloadData() {
    this.isLoading = true;
    this.showNoData = false;
    const id = this.$router.currentRoute.params.id || "";
    if (id !== "") {
      this.fetchEventDetail(id);
      this.fetchAssetWithId(id);
      this.fetchUsageActualHours(id);
    }
    this.selectedTab = 0;
  }

  onChangeTab(index: number) {
    this.selectedTab = index;
    this.activeEventDetailTab = index;
  }

  async fetchAssetWithId(id: string) {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/list/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  async fetchEventDetail(id: string) {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-accel-gyro/${id}?daily=true`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.basicAccelGyroData = await response.json();

        // if (this.basicAccelGyroData.length === 0) {
        //   this.basicAccelGyroData = "";
        // }
      } else {
        this.basicAccelGyroData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  async fetchUsageActualHours(id: string) {
    try {
      this.isLoading = true;
      const url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-actual-usage/${id}?daily=true`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        this.usageActualHoursData = await response.json();

        // if (this.usageActualHoursData.length === 0) {
        //   this.usageActualHoursData = "";
        // }
      } else {
        this.usageActualHoursData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }
}
