













































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CircleIcon,
  TagIcon,
  AwardIcon,
  StarIcon,
  MapPinIcon,
  MapIcon,
  ListIcon,
  UserIcon,
  BarChart2Icon,
} from "vue-feather-icons";

@Component({
  components: {
    CircleIcon,
    TagIcon,
    AwardIcon,
    StarIcon,
    MapPinIcon,
    MapIcon,
    ListIcon,
    UserIcon,
    BarChart2Icon,
  },
})
export default class AssetPreview extends Vue {
  @Prop() basicDetailData!: {};

  @Prop() onChangeTab!: Function;

  @Prop() activeTab = 0;
  tabs = ["Telematics", "Usage VS Actual"];

  onClickTab(index: number) {
    this.activeTab = index;
    this.onChangeTab(index);
  }

  async killDevice(id: string, cmd: string) {
    const response = await fetch(
      `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/remote-ops/${id}`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({cmd}),
      }
    );
  }
}
