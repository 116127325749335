<script>
//Importing Line class from the vue-chartjs wrapper
import { Line } from "vue-chartjs";
import moment from "moment";

//Exporting this so it can be used in other components
export default {
  extends: Line,
  props: {
    basicAccelGyroData: [],
  },
  data() {
    return {
      currentIndex: 0,
      datacollection: {
        datasets: [
          {
            label: "temperature",
            fill: false,
            pointBackgroundColor: "#9d0208",
            borderWidth: 1,
            borderColor: "#dc2f02",
            data: [],
            radius: 0,
          },
        ],
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        animation: false,
        parsing: false,

        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              display: false,
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.updateChartData();
  },

  watch: {
    basicAccelGyroData: function () {
      this.updateChartData();
    },
  },
  methods: {
    updateChartData: function () {
      this.datacollection.labels = this.basicAccelGyroData.map((x) =>
        moment(x.time).format("DD-MM-YYYY hh:mm a")
      );
      this.datacollection.datasets[0].data = this.basicAccelGyroData.map(
        (x) => x.temp
      );

      // const arr = [];
      // const time = [];
      // for (let i = 0; i < this.basicAccelGyroData.length - 9; i++) {
      //   if (i % 10 === 0) {
      //     arr.push(
      //       (this.basicAccelGyroData[i].temp +
      //         this.basicAccelGyroData[i + 1].temp +
      //         this.basicAccelGyroData[i + 2].temp +
      //         this.basicAccelGyroData[i + 3].temp +
      //         this.basicAccelGyroData[i + 4].temp +
      //         this.basicAccelGyroData[i + 5].temp +
      //         this.basicAccelGyroData[i + 6].temp +
      //         this.basicAccelGyroData[i + 7].temp +
      //         this.basicAccelGyroData[i + 8].temp +
      //         this.basicAccelGyroData[i + 9].temp) /
      //         10
      //     );
      //     time.push(
      //       moment(this.basicAccelGyroData[i + 5].time).format(
      //         "DD-MM-YYYY hh:mm a"
      //       )
      //     );
      //   }
      // }

      // this.datacollection.labels = time;
      // this.datacollection.datasets[0].data = arr;
      this.renderChart(this.datacollection, this.options);
    },
  },
};
</script>