<template>
  <apexcharts
    ref="tempChart"
    height="350px"
    type="line"
    :options="chartOptions"
    :series="series"
  ></apexcharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    basicAccelGyroData: [],
  },
  data: function () {
    return {
      series: [
        {
          name: "temp °C",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "line",
          height: 350,
          stacked: true,
          animations: {
            enabled: true,
          },
        },
        colors: ["#fb8500"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shadeIntensity: 1,
        //     inverseColors: false,
        //     opacityFrom: 0.5,
        //     opacityTo: 0,
        //     stops: [0, 90, 100],
        //   },
        // },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
          title: {
            text: "Temp",
          },
        },
        xaxis: {
          type: "datetime",
          title: {
            text: "Time",
          },
        },
      },
    };
  },
  mounted: function () {
    this.updateChartData();
  },

  watch: {
    basicAccelGyroData: function () {
      this.updateChartData();
    },
  },
  methods: {
    updateChartData: function () {
      this.series[0].data = this.basicAccelGyroData.map((x) => [
        new Date(x.time).getTime(),
        x.temp,
      ]);

      this.$refs.tempChart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
        ],
        false,
        true
      );
    },
  },
};
</script>
