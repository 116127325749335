























































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ActualUsageHours from "../../charts/ActualUsageHours.vue";

import DaterangePicker from "../../ui/Daterange-Picker.vue";

import moment from "moment";
import XLSX from "xlsx";

@Component({
  components: { ActualUsageHours, DaterangePicker },
})
export default class AssetUsageActualHours extends Vue {
  @Prop() basicAccelGyroData!: [];
  @Prop() basicDetailData!: any;

  accelChartData: any = [...this.basicAccelGyroData];

  accelChartPicker = "daily";

  @Watch("accelChartPicker")
  watchaccelChartPicker() {
    this.fetchEventDetail("", "", this.accelChartPicker);
  }

  onAccelDateChange(dateRange) {
    this.fetchEventDetail(
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD"),
      ""
    );
  }

  async fetchEventDetail(startDate: string, endDate: string, picker: string) {
    try {
      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-actual-usage/${this.basicDetailData.id}`;

      if (startDate && endDate) {
        if (startDate === endDate) {
          url += `?fixedDate=${startDate}`;
        } else {
          url += `?startDate=${startDate}&endDate=${endDate}`;
        }
      } else if (picker) {
        url += `?${picker}=true`;
      }

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        const data = await response.json();
        if (data) {
          this.accelChartData = [...data];
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  mounted() {
    this.fetchEventLogSheet(this.logSheetPicker);
  }

  logSheetData: any = "";
  logSheetPicker = "daily";

  @Watch("logSheetPicker") onwatchlogSheetPicker() {
    this.fetchEventLogSheet(this.logSheetPicker);
  }

  async fetchEventLogSheet(picker: string) {
    try {
      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/log-sheet/${this.basicDetailData.id}`;

      if (picker) {
        url += `?${picker}=true`;
      }
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        const data = await response.json();
        if (data) {
          this.logSheetData = [...data];
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  onGenerateReport() {
    if (this.logSheetData.length > 0) {
      const data = XLSX.utils.json_to_sheet(
        this.logSheetData.map((x) => ({
          Date: moment(x.time).format("DD-MM-YYYY hh:mm a"),
          "Tag In Time": moment(x.tagIn).format("DD-MM-YYYY hh:mm a"),
          "Tag Out Time": moment(x.tagOut).format("DD-MM-YYYY hh:mm a"),
          "Usage Hours": x.usageHours,
          "Overtime Hours": x.overtime,
          "Actual Hours": x.actualHours,
        }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "internal_report.xlsx");

      const data1 = XLSX.utils.json_to_sheet(
        this.logSheetData.map((x) => ({
          Date: moment(x.time).format("DD-MM-YYYY hh:mm a"),
          "Tag In Time": moment(x.tagIn).format("DD-MM-YYYY hh:mm a"),
          "Tag Out Time": moment(x.tagOut).format("DD-MM-YYYY hh:mm a"),
          "Usage Hours": x.usageHours,
          "Overtime Hours": x.overtime,
        }))
      );
      const wb1 = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb1, data1, "data1");
      XLSX.writeFile(wb1, "customer_report.xlsx");
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Failed!",
        text: "No Log sheet found",
        type: "Danger",
      });
    }
  }
}
