<script>
//Importing Line class from the vue-chartjs wrapper
import { Line } from "vue-chartjs";
import moment from "moment";

//Exporting this so it can be used in other components
export default {
  extends: Line,
  props: {
    basicAccelGyroData: [],
  },
  data() {
    return {
      currentIndex: 0,
      datacollection: {
        datasets: [
          {
            label: "Usage Hours",
            fill: false,
            pointBackgroundColor: "#153d8e",
            borderWidth: 1,
            borderColor: "#2564d5",
            data: [],
          },
          {
            label: "Actual Hours",
            fill: false,
            pointBackgroundColor: "#9d0208",
            borderWidth: 1,
            borderColor: "#dc2f02",
            data: [],
          },
        ],
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        tooltips: {
          mode: "nearest",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.updateChartData();
  },
  watch: {
    basicAccelGyroData: function () {
      this.updateChartData();
    },
  },
  methods: {
    updateChartData: function () {
      this.datacollection.labels = this.basicAccelGyroData.map((x) =>
        moment(x.time).format("DD-MM-YYYY hh:mm a")
      );
      this.datacollection.datasets[0].data = this.basicAccelGyroData.map(
        (x) => x.usageHours
      );
      this.datacollection.datasets[1].data = this.basicAccelGyroData.map(
        (x) => x.actualHours
      );

      this.renderChart(this.datacollection, this.options);
    },
  },
};
</script>